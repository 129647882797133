import React, { useState, useEffect } from 'react'
import { Link } from '@reach/router'
import SVG from 'react-inlinesvg'
import Details from './Details'
import Header from './shared/Header'
import Activities from './shared/Activities'
import ContractStatus from './shared/ContractStatus'
import { Panel } from '../shared/elements'
import * as API from '../../services/api'
import { contractsPath } from '../../utils/paths'

export default function Show(props) {
  const { uuid } = props
  const [record, setRecord] = useState(null)

  useEffect(() => {
    if (record === null) {
      API.getContract(uuid).then((response) => {
        setRecord(response?.data?.contract)
      })
    }
  }, [uuid, record])

  if (record === null) {
    return null
  }

  const onNoteSubmit = async (message) => {
    const response = await API.createNoteActivity({
      activity: {
        contractUuid: uuid,
        message,
      },
    })

    if (response?.data?.activity) {
      const newRecord = {
        ...record,
        activities: [response.data.activity, ...record.activities],
      }
      setRecord(newRecord)
    }
  }

  return (
    <>
      <Header>
        <Link to={contractsPath()} className="back-link">
          <SVG src="/images/icons/arrow-left.svg" />
        </Link>
        <h4>{record.code}</h4>
        <ContractStatus record={record} />
      </Header>
      <div className="row mt-20">
        <div className="col-lg-8 mb-20">
          <Details record={record} setRecord={setRecord} />
        </div>
        <div className="col-lg-4 mb-20">
          <Panel className="full-height">
            <Activities
              activities={record.activities}
              onSubmit={onNoteSubmit}
            />
          </Panel>
        </div>
      </div>
    </>
  )
}
