import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { useOutsideClick } from '../../hooks/outsideClick'

const Container = styled.div`
  position: relative;
  top: -15px;

  button.icon-btn {
    padding: 4px;
    background: transparent;
    border: none;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: var(--text-secondary);
    outline: none;
    transition: color 0.3s ease-in-out;

    &:hover,
    &:focus {
      color: var(--text-primary);
    }

    svg {
      width: 22px;
      height: 22px;
    }
  }

  &.open {
    button.icon-btn {
      color: var(--text-primary);
      background: var(--input-background);
      box-shadow: 2px 4px 6px 0 rgba(40, 46, 103, 0.06);
    }

    .menu {
      opacity: 1;
    }
  }

  &:not(.open) {
    .menu {
      opacity: 0;
      pointer-events: none;
    }
  }

  .menu {
    position: absolute;
    top: 30px;
    right: 0;
    width: 164px;
    padding: 4px;
    border-radius: 6px;
    border-top-right-radius: 0;
    box-shadow: 2px 4px 6px 0 rgba(40, 46, 103, 0.06);
    background: var(--input-background);
    z-index: 100;
    transition: opacity 0.3s ease-in-out;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    button {
      width: 100%;
      padding: 7px 16px;
      border: none;
      border-radius: 4px;
      font-size: 14px;
      line-height: 20px;
      color: var(--text-primary);
      background: transparent;
      text-align: left;
      font-family: var(--body-secondary-font);

      &:hover {
        background: var(--input-background-hover);
      }
    }
  }
`

export default function ContractMenu(props) {
  const {
    record,
    editContract,
    pauseContract,
    unpauseContract,
    terminateContract,
  } = props

  const [menuOpen, setMenuOpen] = useState(false)

  const menuRef = useRef()
  useOutsideClick(menuRef, () => {
    setMenuOpen(false)
  })

  const handleButtonClick = (callback) => () => {
    setMenuOpen(false)
    callback()
  }

  const isPending = record.status === 'pending' || record.status === 'sent'
  const isPaused = record.pausedAt
  const isTerminated = record.terminatedAt
  const isRunning = !isPending && !isPaused && !isTerminated

  const showEdit = isPending || isRunning
  const showPause = isRunning
  const showUnpause = isPaused
  const showTerminate = isRunning

  if (!showEdit && !showPause && !showUnpause && !showTerminate) {
    return null
  }

  return (
    <Container ref={menuRef} className={menuOpen ? 'open' : ''}>
      <button className="icon-btn" onClick={() => setMenuOpen(!menuOpen)}>
        <SVG src="/images/icons/cog.svg" />
      </button>
      <div className="menu">
        <ul>
          {showEdit && (
            <li>
              <button onClick={handleButtonClick(editContract)}>
                Edit contract
              </button>
            </li>
          )}
          {showTerminate && (
            <li>
              <button onClick={handleButtonClick(terminateContract)}>
                Terminate contract
              </button>
            </li>
          )}
          {showPause && (
            <li>
              <button onClick={handleButtonClick(pauseContract)}>
                Pause tracking
              </button>
            </li>
          )}
          {showUnpause && (
            <li>
              <button onClick={handleButtonClick(unpauseContract)}>
                Continue tracking
              </button>
            </li>
          )}
        </ul>
      </div>
    </Container>
  )
}
