import React from 'react'
import styled from 'styled-components'
import {
  listProgressStatusLabels,
  listProgressStatusIndex,
  // listProgressStatusIndexMax,
} from '../../../utils/constants'
import { getExtendedContractStatus } from '../../../utils/helpers'

const Status = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    color: var(--text-primary);
    font-size: 12px;
    font-weight: bold;
  }

  .progress-pills {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 130px;
    height: 3px;
    margin-top: 10px;

    > div {
      flex: 1;
      height: 3px;
      border-radius: 2px;
      margin: 0 1px;
    }

    .progress-pill-1 {
      background: #ccd7e5;
    }
    .progress-pill-2 {
      background: #ffcf5f;
    }
    .progress-pill-3 {
      background: #ffcf5f;
    }
    .progress-pill-4 {
      background: #75b0d9;
    }
    .progress-pill-5 {
      background: #78d4c0;
    }
    .progress-pill-inactive {
      background: #ebeff4;
    }
  }
`

export default function Progress(props) {
  const { record } = props

  const status = getExtendedContractStatus(record)

  const label = listProgressStatusLabels[status] || ''
  const progress = listProgressStatusIndex[status] || 0

  const inactive = progress === 0
  const ended = status === 'ended'

  return (
    <Status>
      <span className={`${inactive ? 'inactive' : ''} ${ended ? 'ended' : ''}`}>
        {label}
      </span>
      {/*progress > 0 && (
        <div className="progress-pills">
          {Array(listProgressStatusIndexMax)
            .fill()
            .map((_, i) => (
              <div
                key={`pill-${i}`}
                className={`progress-pill-${progress} ${
                  progress >= i + 1
                    ? 'progress-pill-active'
                    : 'progress-pill-inactive'
                }`}
              />
            ))}
        </div>
      )*/}
    </Status>
  )
}
