import React from 'react'
import { CollapsibleFieldset } from './shared'
import { TextField, TextSuggestField, CountrySelect } from '../../shared/fields'
import { validates } from '../../../utils/constants'

export default function FieldsetCompany(props) {
  const {
    form,
    updateCompany,
    updateBilling,
    companyNames,
    forceRender,
  } = props

  return (
    <CollapsibleFieldset label="Company*">
      <div className="row">
        <div className="col-md-6">
          <TextSuggestField
            form={form}
            defaultValue={form.getValues()?.data?.companyName}
            suggestions={companyNames || []}
            validation={validates.required}
            name="data.companyName"
            label="Company name*"
            onChange={() => {
              updateCompany()
              updateBilling()
            }}
          />
        </div>
        <div className="col-md-6">
          <TextField
            form={form}
            validation={validates.email}
            name="data.companyEmail"
            label="Company e-mail"
            onChange={updateBilling}
          />
        </div>
      </div>
      <TextField
        form={form}
        name="data.companyAddress"
        label="Company address"
        onChange={updateBilling}
      />
      <div className="row">
        <div className="col-md-4">
          <TextField
            form={form}
            name="data.companyZip"
            label="Postal code"
            onChange={updateBilling}
          />
        </div>
        <div className="col-md-8">
          <TextField
            form={form}
            name="data.companyCity"
            label="City"
            onChange={updateBilling}
          />
        </div>
      </div>
      <CountrySelect
        form={form}
        name="data.companyCountry"
        label="Country"
        onCountryChange={updateBilling}
        forceRender={forceRender}
      />
      <div className="row">
        <div className="col-md-6">
          <TextField form={form} name="data.companyVat" label="VAT number" />
        </div>
      </div>
    </CollapsibleFieldset>
  )
}
