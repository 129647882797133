import React, { useState, useEffect, useCallback } from 'react'
import { CollapsibleFieldset } from './shared'
import { MaskField, SelectField, DateField } from '../../shared/fields'
import {
  currencies,
  commissionModels,
  billingTypes,
  periodTypes,
  trackingImplementations,
} from '../../../utils/constants'
import { getValue } from '../../../utils/helpers'

export default function FieldsetContract(props) {
  const { form, isDefault } = props
  const { getValues } = form

  const [commissionPriceModel, setCommissionPriceModel] = useState(null)
  const updateCommissionPriceModel = useCallback(() => {
    if (getValue(getValues, 'data.commissionModel') === 'CPS') {
      setCommissionPriceModel('percentage')
    } else {
      setCommissionPriceModel('price')
    }
  }, [getValues])

  useEffect(() => {
    if (getValue(getValues, 'data.commissionModel')) {
      updateCommissionPriceModel()
    }
  }, [getValues, updateCommissionPriceModel])

  return (
    <CollapsibleFieldset label="Contract details">
      {isDefault ? (
        <div className="row">
          <div className="col-md-6 col-xl-4">
            <MaskField
              form={form}
              name="data.contractPeriod"
              label="Contract length"
              mask={Array(20).fill(/\d/)}
            />
          </div>
          <div className="col-md-6 col-xl-3">
            <SelectField
              form={form}
              name="data.contractPeriodType"
              search={false}
              options={periodTypes}
            />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-6">
            <DateField
              form={form}
              name="data.contractLength"
              label="Contract length"
            />
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-md-6 col-lg-4">
          <MaskField
            form={form}
            name="data.initialFee"
            label="Initial fee"
            mask={Array(20).fill(/\d/)}
          />
        </div>
        <div className="col-md-6 col-lg-4">
          <MaskField
            form={form}
            name="data.monthlyFee"
            label="Monthly fee"
            mask={Array(20).fill(/\d/)}
          />
        </div>
        <div className="col-md-6 col-lg-4">
          <SelectField
            form={form}
            name="data.currency"
            label="Currency"
            search={false}
            options={currencies}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <SelectField
            form={form}
            name="data.commissionModel"
            label="Commission model"
            search={false}
            options={commissionModels}
            onSelectChange={updateCommissionPriceModel}
          />
        </div>
        <div
          className={`col-md-8 ${
            commissionPriceModel === 'percentage' || 'd-none'
          }`}
        >
          <MaskField
            form={form}
            name="data.commissionPercentage"
            label="Commission percentage"
            mask={Array(2).fill(/\d/)}
          />
        </div>
        <div
          className={`col-md-8 ${commissionPriceModel === 'price' || 'd-none'}`}
        >
          <MaskField
            form={form}
            name="data.commissionPrice"
            label="Commission price"
            mask={Array(20).fill(/\d|\./)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-8">
          <SelectField
            form={form}
            name="data.billingType"
            label="Billing"
            search={false}
            options={billingTypes}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-xl-4">
          <MaskField
            form={form}
            name="data.transactionDeclinePeriod"
            label="Transaction decline period"
            mask={Array(3).fill(/\d/)}
          />
        </div>
        <div className="col-md-6 col-xl-3">
          <SelectField
            form={form}
            name="data.transactionDeclinePeriodType"
            search={false}
            options={periodTypes}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-xl-4">
          <MaskField
            form={form}
            name="data.invoicePeriod"
            label="Invoice period in days"
            mask={Array(3).fill(/\d/)}
          />
        </div>
        <div className="col-md-6 col-xl-3 d-none">
          <SelectField
            form={form}
            name="data.invoicePeriodType"
            search={false}
            options={periodTypes}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-xl-4">
          <MaskField
            form={form}
            name="data.trackingPeriod"
            label="Tracking period in days"
            mask={Array(3).fill(/\d/)}
          />
        </div>
        <div className="col-md-6 col-xl-3 d-none">
          <SelectField
            form={form}
            name="data.trackingPeriodType"
            search={false}
            options={periodTypes}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <SelectField
            form={form}
            name="data.trackingImplementation"
            label="Tracking implementation"
            search={false}
            options={trackingImplementations}
          />
        </div>
      </div>
    </CollapsibleFieldset>
  )
}
