import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import SVG from 'react-inlinesvg'
import moment from 'moment'
import FieldsetCompany from './form/1_Company'
import FieldsetContact from './form/2_Contact'
import FieldsetBilling from './form/3_Billing'
import FieldsetManager from './form/4_Manager'
import FieldsetContract from './form/5_Contract'
import { Form as StyledForm } from './form/shared'
import { TextField } from '../shared/fields'
import * as API from '../../services/api'
import { getCurrentUser } from '../../services/auth'
import {
  validates,
  billingTypes,
  trackingImplementations,
} from '../../utils/constants'
import {
  parseQuery,
  getCompany,
  touchTextField,
  touchCountryField,
  clearUndefinedNestedErrors,
} from '../../utils/helpers'

const defaultDataValues = {
  companyName: '',
  companyEmail: '',
  companyAddress: '',
  companyZip: '',
  companyCity: '',
  companyCountry: '',
  companyVat: '',
  sameBillingAddress: false,

  billingName: '',
  billingEmail: '',
  billingAddress: '',
  billingZip: '',
  billingCity: '',
  billingCountry: '',

  contactName: '',
  contactPosition: '',
  contactPhone: '',
  contactEmail: '',
  contactIp: '',

  managerName: '',
  managerPosition: '',
  managerEmail: '',
  managerIp: '',

  initialFee: '10000',
  monthlyFee: '1000',
  currency: 'DKK',
  contractLength: moment().add(1, 'year').toDate(),
  commissionModel: 'CPA',
  commissionPercentage: '10',
  commissionPrice: '100',
  billingType: billingTypes[0].value,
  transactionDeclinePeriod: '15',
  transactionDeclinePeriodType: 'days',
  invoicePeriod: '7',
  invoicePeriodType: 'days',
  trackingPeriod: '30',
  trackingPeriodType: 'days',
  trackingImplementation: trackingImplementations[0].value,
}

export default function Form(props) {
  const query = parseQuery()

  const { isNew } = props

  const [forceRender, setForceRender] = useState(0)
  const [advertisers, setAdvertisers] = useState(null)

  useEffect(() => {
    if (!advertisers) {
      API.getContractFormData().then((response) => {
        if (response?.data?.advertisers) {
          setAdvertisers(response.data.advertisers)
        }
      })
    }
  }, [advertisers])

  const currentUser = getCurrentUser()
  const initialManager = {
    managerName: currentUser.name || '',
    managerEmail: currentUser.email || '',
    managerPosition: 'Manager',
  }

  const initialValues = props.initialValues || {}
  const initialData = {
    ...defaultDataValues,
    ...initialManager,
    ...initialValues.data,
    ...initialValues.pendingData,
  }
  const currentStatus = props.initialValues?.status || 'pending'

  const isPending = currentStatus === 'pending' || currentStatus === 'sent'
  const canSend = currentStatus === 'pending'
  const canUpdate = currentStatus === 'sent'
  const shouldPropose = !isPending

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      code: initialValues.code || '',
      url: initialValues.url || '',
      data: initialData,
    },
  })

  clearUndefinedNestedErrors(form, 'data')

  const getSelectedCompany = useCallback(
    (name) => {
      if (!name) {
        const values = form.getValues()
        name = values?.data?.companyName
      }

      return getCompany(advertisers, name)
    },
    [advertisers, form]
  )

  const companyNames = useMemo(() => {
    if (advertisers) {
      return advertisers.map((company) => company.name)
    } else {
      return []
    }
  }, [advertisers])

  const updateCompany = useCallback(() => {
    const selectedCompany = getSelectedCompany()

    setForceRender(Date.now())

    if (selectedCompany) {
      form.setValue('data.companyName', selectedCompany.name)
      form.setValue('data.companyEmail', selectedCompany.email)
      form.setValue('data.companyAddress', selectedCompany.company.address)
      form.setValue('data.companyZip', selectedCompany.company.zip)
      form.setValue('data.companyCity', selectedCompany.company.city)
      form.setValue('data.companyCountry', selectedCompany.company.country)
      form.setValue('data.companyVat', selectedCompany.company.vat)

      form.trigger('data.companyName')

      touchTextField('data.companyname')
      touchTextField('data.companyemail')
      touchTextField('data.companyaddress')
      touchTextField('data.companyzip')
      touchTextField('data.companycity')
      touchTextField('data.companyvat')
      touchCountryField('data.companyCountry')
    }
  }, [getSelectedCompany, form])

  const updateBilling = useCallback(() => {
    const values = form.getValues()

    setForceRender(Date.now())

    if (values.data.sameBillingAddress) {
      form.setValue('data.billingName', values.data.companyName)
      form.setValue('data.billingEmail', values.data.companyEmail)
      form.setValue('data.billingAddress', values.data.companyAddress)
      form.setValue('data.billingZip', values.data.companyZip)
      form.setValue('data.billingCity', values.data.companyCity)
      form.setValue('data.billingCountry', values.data.companyCountry)

      touchTextField('data.billingname')
      touchTextField('data.billingemail')
      touchTextField('data.billingaddress')
      touchTextField('data.billingzip')
      touchTextField('data.billingcity')
      touchCountryField('data.billingCountry')
    }
  }, [form])

  useEffect(() => {
    const values = form.getValues()

    if (
      advertisers &&
      query?.company &&
      values?.data?.companyName.toLowerCase() !== query.company.toLowerCase()
    ) {
      form.setValue('data.companyName', query.company)
      updateCompany()
    }
  }, [advertisers, updateCompany, query, form])

  const submit = (submitType) => () => {
    form.handleSubmit(props.onSubmit(submitType))()
  }

  const fieldsetParams = {
    form,
    companyNames,
    updateCompany,
    updateBilling,
    forceRender,
  }

  return (
    <StyledForm>
      <div className="form-body">
        {isNew && (
          <TextField
            form={form}
            validation={validates.required}
            name="code"
            label="Agreement name*"
          />
        )}

        <TextField
          form={form}
          validation={validates.required}
          name="url"
          label="Advertiser URL*"
          disabled={!isPending}
        />

        {isPending && (
          <>
            <FieldsetCompany {...fieldsetParams} />
            <FieldsetContact {...fieldsetParams} />
            <FieldsetBilling {...fieldsetParams} />
          </>
        )}
        <FieldsetManager {...fieldsetParams} />
        <FieldsetContract {...fieldsetParams} />
      </div>

      <div className="form-actions">
        {canSend && (
          <>
            <button
              type="button"
              className="btn btn-primary"
              onClick={submit('save')}
            >
              Save for now
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={submit('send')}
            >
              Send signup link
              <SVG src="/images/icons/arrow-point-right.svg" />
            </button>
          </>
        )}
        {canUpdate && (
          <button
            type="button"
            className="btn btn-primary"
            onClick={submit('save')}
          >
            Update
          </button>
        )}
        {shouldPropose && (
          <button
            type="button"
            className="btn btn-primary"
            onClick={submit('save')}
          >
            Propose new terms
          </button>
        )}
      </div>
    </StyledForm>
  )
}
