import React from 'react'
import { CollapsibleFieldset } from './shared'
import {
  TextField,
  TextSuggestField,
  Checkbox,
  CountrySelect,
} from '../../shared/fields'
import { emailRegex } from '../../../utils/constants'

export default function FieldsetBilling(props) {
  const { form, updateBilling, companyNames, forceRender } = props

  const disabled = !!form.getValues('data.sameBillingAddress')

  return (
    <CollapsibleFieldset label="Billing">
      <Checkbox
        form={form}
        name="data.sameBillingAddress"
        label="Same address as company"
        onChange={updateBilling}
      />
      <div className="row">
        <div className="col-md-6">
          <TextSuggestField
            form={form}
            suggestions={companyNames || []}
            name="data.billingName"
            label="Company name"
            disabled={disabled}
          />
        </div>
        <div className="col-md-6">
          <TextField
            form={form}
            validation={{ pattern: emailRegex }}
            name="data.billingEmail"
            label="E-mail"
            disabled={disabled}
          />
        </div>
      </div>
      <TextField
        form={form}
        name="data.billingAddress"
        label="Company address"
        disabled={disabled}
      />
      <div className="row">
        <div className="col-md-4">
          <TextField
            form={form}
            name="data.billingZip"
            label="Postal code"
            disabled={disabled}
          />
        </div>
        <div className="col-md-8">
          <TextField
            form={form}
            name="data.billingCity"
            label="City"
            disabled={disabled}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <CountrySelect
            form={form}
            name="data.billingCountry"
            label="Country"
            disabled={disabled}
            forceRender={forceRender}
          />
        </div>
      </div>
    </CollapsibleFieldset>
  )
}
