import styled from 'styled-components'

const Header = styled.div`
  position: relative;

  .back-link {
    position: relative;
    top: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    margin-right: 20px;
    color: var(--text-primary);

    svg {
      max-width: 100%;
      max-height: 100%;
    }

    &:hover {
    }
  }

  h4 {
    display: inline-block;
    margin: 0;
    font-size: 20px;
  }
`

export default Header
