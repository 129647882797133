import React from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;

  .filter-search {
    display: inline-block;
    position: relative;
    flex: 1;
    margin-left: 10px;

    button.submit,
    button.cancel {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      height: 46px;
      background: transparent;
      border: none;
      outline: none;
      appearance: none;

      svg {
        width: 22px;
        height: 22px;
      }
    }

    button.submit {
      justify-content: flex-end;
      left: 0;
      width: 46px;
    }

    button.cancel {
      justify-content: flex-start;
      right: 0;
      width: 40px;
      opacity: 0.4;
    }

    input {
      width: 100%;
      height: 46px;
      padding: 0 52px;
      background: var(--input-background);
      border: none;
      border-radius: 4px;

      &::placeholder {
        color: var(--text-secondary);
      }
    }
  }

  .filter-dropdown {
    display: inline-block;
    margin-left: 10px;

    select {
      width: 120px;
      height: 46px;
      padding: 10px 14px;
      border: none;
      border-radius: 4px;
      background: var(--input-background);
    }
  }
`

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const years = []
for (let i = 2021; i <= new Date().getFullYear(); i++) years.push(i.toString())

export default function InvoicesFilter(props) {
  const {
    filterQuery,
    setFilterQuery,
    filterMonth,
    setFilterMonth,
    filterYear,
    setFilterYear,
    reloadRecords,
  } = props

  return (
    <Container>
      <div className="filter-search">
        <button type="button" className="submit" onClick={reloadRecords}>
          <SVG src="/images/icons/search.svg" />
        </button>
        <input
          value={filterQuery}
          onChange={(event) => {
            setFilterQuery(event.target.value)
          }}
          onKeyDown={(event) => {
            switch (event.key) {
              case 'Enter':
                reloadRecords()
                break
              case 'Escape':
                setFilterQuery('')
                break
              default:
            }
          }}
          placeholder="Search advertiser"
        />
        {filterQuery && (
          <button
            type="button"
            className="cancel"
            onClick={() => {
              setFilterQuery('')
              reloadRecords()
            }}
          >
            <SVG src="/images/icons/times.svg" />
          </button>
        )}
      </div>

      <div className="filter-dropdown">
        <select
          className="text-primary"
          value={filterMonth}
          onChange={(event) => {
            setFilterMonth(event.target.value)
            if (filterMonth !== event.target.value) {
              reloadRecords()
            }
          }}
          onBlur={(event) => {
            setFilterMonth(event.target.value)
            if (filterMonth !== event.target.value) {
              reloadRecords()
            }
          }}
        >
          {months.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div className="filter-dropdown">
        <select
          className="text-primary"
          value={filterYear}
          onChange={(event) => {
            setFilterYear(event.target.value)
            if (filterYear !== event.target.value) {
              reloadRecords()
            }
          }}
          onBlur={(event) => {
            setFilterYear(event.target.value)
            if (filterYear !== event.target.value) {
              reloadRecords()
            }
          }}
        >
          {years.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </Container>
  )
}
