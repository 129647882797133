import React from 'react'
import { navigate } from '@reach/router'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import moment from 'moment'
import Progress from './Progress'
import Owner from './Owner'
import { formatMoneyValue } from '../../../utils/helpers'
import { contractPath } from '../../../utils/paths'

const StyledTable = styled.table.attrs((props) => ({
  className: 'table table-borderless',
}))`
  margin-top: 30px;

  thead {
    th,
    td {
      height: 32px;
      color: var(--text-secondary);
      font-size: 12px;
      font-weight: normal;
      cursor: pointer;
    }

    td {
      text-align: center;
    }
  }

  tbody {
    color: var(--text-primary);
    font-size: 12px;

    th,
    td {
      vertical-align: middle;
    }

    th {
      font-size: 14px;
      white-space: nowrap;
    }

    td {
      position: relative;
      text-align: center;
      font-weight: bold;
    }

    .with-separator {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 25%;
        right: 0;
        bottom: 25%;
        width: 1px;
        background: var(--text-muted);
      }
    }

    tr {
      > *:not(.spacer) {
        border-bottom: 1px solid var(--text-muted);
      }
    }

    tr {
      cursor: pointer;
      transition: box-shadow 0.5s ease-in-out;

      .hover-icon {
        position: relative;
        display: inline-block;
        left: -20px;
        width: 0px;
        margin-right: 0px;
        color: var(--text-active);
        opacity: 0;
        vertical-align: middle;
        overflow: hidden;
        transition: left 0.3s ease-in-out, width 0.3s ease-in-out,
          margin 0.3s ease-in-out, opacity 0.4s ease-in-out;

        svg {
          width: 22px;
          height: 22px;
        }
      }

      .contract-code {
        position: relative;
        left: 0;
        display: inline-block;
        white-space: normal;
        vertical-align: middle;
        transition: left 0.3s ease-in-out;
      }

      &:hover {
        box-shadow: 0px 1px 6px 0 rgba(156, 178, 211, 0.24);
        z-index: 1;

        .hover-icon {
          left: 0;
          width: 22px;
          margin-right: -22px;
          opacity: 1;
        }

        .contract-code {
          left: 22px;
        }
      }
    }
  }

  .sort-up {
    position: relative;
    top: 1px;
    left: 8px;
    transform: rotate(180deg);
  }

  .sort-down {
    position: relative;
    top: -2px;
    left: 8px;
  }

  .sort-none {
    display: none;
  }

  .spacer {
    width: 30px;
    min-width: 30px;
    padding: 0;
  }
`

export default function ContractsTable(props) {
  const { records, setRecords, order, setOrder, sort, setSort, setPage } = props

  const sortArrowClass = (btnOrder) => {
    if (btnOrder === order) {
      if (sort === 'asc') {
        return 'sort-up'
      } else {
        return 'sort-down'
      }
    } else {
      return 'sort-none'
    }
  }

  const sortClick = (btnOrder, btnSort) => () => {
    if (btnOrder === order) {
      if (sort === 'asc') {
        setSort('desc')
        setRecords(null)
        setPage(1)
      } else {
        setSort('asc')
        setRecords(null)
        setPage(1)
      }
    } else {
      setOrder(btnOrder)
      setSort(btnSort)
      setRecords(null)
      setPage(1)
    }
  }

  const sortKey = (btnOrder, btnSort) => (event) => {
    if (event.key === 'Enter' || event.key === 'Space') {
      sortClick(btnOrder, btnSort)()
    }
  }

  if (records === null) {
    return (
      <StyledTable>
        <thead>
          <tr>
            <td>Loading...</td>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <td className="text-center text-primary py-5">
              <div className="spinner-border" role="status" />
            </td>
          </tr>
        </tfoot>
      </StyledTable>
    )
  }

  return (
    <StyledTable>
      <thead>
        <tr>
          <td className="spacer" />
          <th
            onClick={sortClick('code', 'asc')}
            onKeyDown={sortKey('code', 'asc')}
          >
            Agreement name
            <SVG
              className={sortArrowClass('code')}
              src="/images/icons/arrow-sort-down.svg"
            />
          </th>
          <td
            onClick={sortClick('status', 'desc')}
            onKeyDown={sortKey('status', 'desc')}
            role="presentation"
          >
            Status
            <SVG
              className={sortArrowClass('status')}
              src="/images/icons/arrow-sort-down.svg"
            />
          </td>
          <td
            onClick={sortClick('data_contact_name', 'asc')}
            onKeyDown={sortKey('data_contact_name', 'asc')}
            role="presentation"
          >
            Contact person
            <SVG
              className={sortArrowClass('data_contact_name')}
              src="/images/icons/arrow-sort-down.svg"
            />
          </td>
          <td
            onClick={sortClick('user.name', 'asc')}
            onKeyDown={sortKey('user.name', 'asc')}
            role="presentation"
          >
            Created by
            <SVG
              className={sortArrowClass('user.name')}
              src="/images/icons/arrow-sort-down.svg"
            />
          </td>
          <td
            onClick={sortClick('revenue_cents', 'desc')}
            onKeyDown={sortKey('revenue_cents', 'desc')}
            role="presentation"
          >
            Value
            <SVG
              className={sortArrowClass('revenue_cents')}
              src="/images/icons/arrow-sort-down.svg"
            />
          </td>
          <td
            onClick={sortClick('last_activity_at', 'desc')}
            onKeyDown={sortKey('last_activity_at', 'desc')}
            role="presentation"
          >
            Last activity
            <SVG
              className={sortArrowClass('last_activity_at')}
              src="/images/icons/arrow-sort-down.svg"
            />
          </td>
          <td className="spacer" />
        </tr>
      </thead>
      <tbody>
        {records.map((record) => (
          <tr
            key={record.uuid}
            onClick={() => navigate(contractPath(record.uuid))}
          >
            <td className="spacer" />
            <th>
              <span className="hover-icon">
                <SVG src="/images/icons/arrow-point-right.svg" />
              </span>
              <span className="contract-code">{record.code}</span>
            </th>
            <td className="with-separator">
              <Progress record={record} />
            </td>
            <td className="with-separator">{record.data?.contactName}</td>
            <td className="with-separator">
              <Owner user={record.user} />
            </td>
            <td className="with-separator">{formatMoneyValue(record)}</td>
            <td>
              {moment(record.lastActivityAt || record.updatedAt).format(
                'DD/MM/YYYY'
              )}
            </td>
            <td className="spacer" />
          </tr>
        ))}
      </tbody>
      {records.length === 0 && (
        <tfoot>
          <tr>
            <td colSpan="42" className="text-center text-primary">
              <div className="pt-4 text-secondary">Nothing found</div>
            </td>
          </tr>
        </tfoot>
      )}
    </StyledTable>
  )
}
