import React, { useRef } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { Collapse, CollapseToggle } from '../../shared/elements'

export const Fieldset = styled.div`
  border-top: 1px solid var(--text-muted);
  border-bottom: 1px solid var(--text-muted);
  padding: 0px 10px;
  margin-bottom: -1px;

  .fieldset-header {
    h5 {
      display: inline-block;
      margin: 0;
      font-size: 16px;
    }

    button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 20px 0;
      margin: 0;
      border: none;
      color: var(--text-primary);
      background: transparent;

      svg {
        transition: transform 0.35s ease-in-out;
      }

      &.open {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .fieldset-body {
    padding-top: 20px;
    margin-bottom: -20px;
  }
`

export const Form = styled.form`
  padding-top: 30px;

  .form-actions {
    text-align: right;
    margin-top: 30px;

    .btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      padding: 15px 20px 13px;
      margin-left: 20px;
      font-size: 16px;

      svg {
        width: 22px;
        height: 22px;
        margin-top: -2px;
        margin-left: 10px;
      }
    }
  }
`

export const CollapsibleFieldset = (props) => {
  const { label, children, initiallyOpen } = props

  const collapseRef = useRef()

  return (
    <Fieldset>
      <Collapse control={collapseRef}>
        <div className="fieldset-header">
          <CollapseToggle controls={collapseRef} initiallyOpen={initiallyOpen}>
            <h5>{label}</h5>
            <SVG src="/images/icons/arrow-down.svg" />
          </CollapseToggle>
        </div>
        <div className="fieldset-body">{children}</div>
      </Collapse>
    </Fieldset>
  )
}
