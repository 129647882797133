import React from 'react'
import { Router, Redirect } from '@reach/router'
import Layout from '../components/layout'
import ContractList from '../components/contracts/List'
import ContractNew from '../components/contracts/New'
import ContractShow from '../components/contracts/Show'
import ContractEdit from '../components/contracts/Edit'
import InvoicesList from '../components/invoices/List'
import { Head } from '../components/shared/elements'
import * as paths from '../utils/paths'

export default (props) => {
  return (
    <>
      <Head />
      <Layout>
        <Router>
          <Redirect from="/" to={paths.contractsPath()} noThrow />

          <ContractList path={paths.contractsPath()} />
          <ContractNew path={paths.newContractPath()} />
          <ContractShow path={paths.contractPath(':uuid')} />
          <ContractEdit path={paths.editContractPath(':uuid')} />

          <InvoicesList path={paths.invoicesPath()} />
        </Router>
      </Layout>
    </>
  )
}
