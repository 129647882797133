import React from 'react'
import { CollapsibleFieldset } from './shared'
import { TextField, TextSuggestField } from '../../shared/fields'
import { validates } from '../../../utils/constants'

export default function FieldsetContact(props) {
  const { form } = props

  return (
    <CollapsibleFieldset label="Contact person*">
      <div className="row">
        <div className="col-md-6">
          <TextSuggestField
            form={form}
            defaultValue={form.getValues()?.data?.contactName}
            validation={validates.required}
            name="data.contactName"
            label="Name"
          />
        </div>
        <div className="col-md-6">
          <TextField
            form={form}
            validation={validates.required}
            name="data.contactPosition"
            label="Position"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <TextField
            form={form}
            validation={{ ...validates.required, ...validates.email }}
            name="data.contactEmail"
            label="E-mail"
          />
        </div>
        <div className="col-md-6">
          <TextField
            form={form}
            name="data.contactPhone"
            label="Phone number"
          />
        </div>
      </div>
    </CollapsibleFieldset>
  )
}
