import React, { useState, useEffect } from 'react'
import MaskedInput from 'react-text-mask'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -10px;

  .prev,
  .next {
    padding: 10px;
    border: none;
    background: transparent;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  .next {
    svg {
      transform: rotate(180deg);
    }
  }

  .page {
    white-space: nowrap;

    input {
      width: 26px;
      height: 26px;
      padding-top: 5px;
      margin: 0 8px;
      border: 2px solid var(--text-muted);
      border-radius: 4px;
      background: var(--input-background);
      text-align: center;

      &.large {
        width: 36px;
      }
    }
  }
`

export default function Pagination(props) {
  const { page, perPage, setPage, total } = props

  const pages = Math.ceil(total / perPage)

  const [showPage, setShowPage] = useState(page)
  const gotoPage = (newPage) => {
    if (newPage > 0 && newPage <= pages) {
      setPage(newPage)
    } else {
      setShowPage(page)
    }
  }

  useEffect(() => {
    setShowPage(page)
  }, [page])

  if (pages <= 1) {
    return null
  }

  return (
    <Container>
      <button className="prev" onClick={() => gotoPage(Number(page) - 1)}>
        <SVG src="/images/icons/arrow-left.svg" />
      </button>
      <div className="page text-primary">
        Page
        <MaskedInput
          className={Number(showPage) > 99 ? 'large' : ''}
          mask={Array(3).fill(/\d/)}
          guide={false}
          value={showPage}
          onChange={(event) => {
            setShowPage(event.target.value)
          }}
          onBlur={() => {
            gotoPage(Number(showPage))
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              gotoPage(Number(showPage))
            }
          }}
        />
        of {pages}
      </div>
      <button className="next" onClick={() => gotoPage(Number(page) + 1)}>
        <SVG src="/images/icons/arrow-left.svg" />
      </button>
    </Container>
  )
}
