import React, { useState, useEffect } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import SalesFilter from './SalesFilter'
import SalesChart from './SalesChart'
import { Panel } from '../../shared/elements'
import * as API from '../../../services/api'
import {
  getFirstStatsDate,
  formatStatTotal,
  formatChartDataFromStats,
} from '../../../utils/chart'

const Container = styled.div`
  &.inactive {
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 250px;
      color: var(--text-secondary);
      background: var(--input-background);
      border: dashed 3px var(--text-muted);
      border-radius: 6px;
      box-shadow: none;
    }
  }

  h5 {
    margin-bottom: 30px;
  }

  .label {
    color: var(--text-secondary);
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.6px;
  }

  .stat-main {
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    white-space: nowrap;
  }

  .stat-value {
    margin-bottom: 4px;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    white-space: nowrap;
  }
`

export default function ContractSales(props) {
  const { record } = props

  const [chartData, setChartData] = useState([])
  const [commissionData, setCommissionData] = useState(0)
  const [amountData, setAmountData] = useState(0)
  const [salesData, setSalesData] = useState(0)
  const resetData = () => {
    setChartData([])
    setCommissionData(0)
    setAmountData(0)
    setSalesData(0)
  }

  const firstDate = getFirstStatsDate(record)
  const lastDate = new Date()
  const [startDate, setStartDate] = useState(() => {
    const date = moment().subtract(14, 'days').toDate()
    if (date < firstDate) {
      return firstDate
    } else {
      return date
    }
  })
  const [endDate, setEndDate] = useState(lastDate)

  const isActive = record.status === 'running' || record.status === 'ended'
  const isInactive = record.status !== 'running' && record.status !== 'ended'

  useEffect(() => {
    if (isActive && startDate && endDate) {
      API.getStats(record.uuid, {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      }).then((response) => {
        const stats = response.data.stats
        if (!stats) {
          return resetData()
        }

        const newChartData = formatChartDataFromStats(stats, startDate, endDate)

        setChartData(newChartData)
        setCommissionData(stats.totals.Stat?.revenue || 0)
        setAmountData(stats.totals.Stat?.sale_amount || 0)
        setSalesData(stats.data.length)
      })
    } else {
      resetData([])
    }
  }, [record, startDate, endDate, isActive])

  if (isActive) {
    return (
      <Container className="active">
        <Panel>
          <div className="row">
            <div className="col-md-4">
              <h5>Stats</h5>
            </div>
            <div className="col-md-8 text-right">
              <SalesFilter
                {...{
                  startDate,
                  setStartDate,
                  endDate,
                  setEndDate,
                  firstDate,
                  lastDate,
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="stat-main text-primary">
                {formatStatTotal(commissionData, record.data.currency)}
              </div>
              <div className="label">Commission</div>
            </div>
            <div className="col-md-4">
              <div className="stat-value text-primary">
                {formatStatTotal(amountData, record.data.currency)}
              </div>
              <div className="label">Sales amount</div>
            </div>
            <div className="col-md-4">
              <div className="stat-value text-primary">{salesData}</div>
              <div className="label">Number of sales</div>
            </div>
          </div>
          <SalesChart record={record} data={chartData} />
          <div className="label">Sales over the period</div>
        </Panel>
      </Container>
    )
  } else if (isInactive) {
    return (
      <Container className="inactive">
        <Panel>
          Stats of your sales will be available here when the tracking is
          running.
        </Panel>
      </Container>
    )
  } else {
    return null
  }
}
