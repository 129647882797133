import React, { useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import ContractMenu from './details/ContractMenu'
import Sales from './details/Sales'
import Invoices from './details/Invoices'
import DialogPause from './shared/DialogPause'
import DialogTerminate from './shared/DialogTerminate'
import { Panel } from '../shared/elements'
import * as API from '../../services/api'
import { editContractPath } from '../../utils/paths'
import {
  getCommissionPriceModel,
  formatContractLength,
} from '../../utils/helpers'

const DetailsContainer = styled.div`
  .heading {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .label {
    display: block;
    margin-top: 20px;
    margin-bottom: 8px;
    color: var(--text-secondary);
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.6px;
  }

  .contract-url {
    word-break: break-word;
  }

  .contract-download {
    display: inline-flex;
    align-items: center;
    color: var(--text-primary);

    .icon-contract {
      width: 22px;
      height: 22px;
      margin-right: 6px;
      color: var(--text-secondary);
    }

    .contract-filename {
      display: inline-block;
      margin-top: 2px;
      margin-right: 14px;
      font-family: var(--body-font);
      font-size: 14px;
      font-weight: normal;
    }

    .icon-download {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 15px;
      background: var(--input-background);
      transition: background 0.2s ease-in-out;

      &:hover {
        background: var(--input-background-hover);
      }

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  .new-appendix-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    color: var(--text-primary);
    background: var(--input-background);
    transition: background 0.2s ease-in-out;
    vertical-align: top;

    &:hover {
      background: var(--input-background-hover);
    }

    svg {
      width: 22px;
      height: 22px;
    }
  }

  .form-actions {
    text-align: right;
    margin-top: 30px;
  }
`

export default function Details(props) {
  const { record, setRecord } = props

  const isProposedUpdate = !record.publisherAgreed

  const contractData = isProposedUpdate
    ? { ...record.data, ...record.pendingData }
    : record.data

  const commissionPriceModel = getCommissionPriceModel(
    contractData.commissionModel
  )

  const [showPauseDialog, setShowPauseDialog] = useState(false)
  const pauseContract = async () => {
    await API.updateContract(record.uuid, {
      pausedAt: new Date(),
    })
    setRecord(null)
  }

  const unpauseContract = async () => {
    await API.updateContract(record.uuid, {
      pausedAt: null,
    })
    setRecord(null)
  }

  const signContract = async () => {
    await API.signContract(record.uuid)
    setRecord(null)
  }

  const declineContract = async () => {
    if (window.confirm('Are you sure?')) {
      await API.declineContract(record.uuid)
      setRecord(null)
    }
  }

  const [showTerminateDialog, setShowTerminateDialog] = useState(false)
  const terminateContract = async (params) => {
    await API.updateContract(record.uuid, {
      status: 'ended',
      terminatedAt: new Date(),
      ...params,
    })
    setRecord(null)
  }

  return (
    <>
      <Panel className="mb-20">
        <DetailsContainer>
          <div className="heading">
            <h5>Partnership agreement</h5>
            <ContractMenu
              record={record}
              editContract={() => navigate(editContractPath(record.uuid))}
              pauseContract={() => setShowPauseDialog(true)}
              unpauseContract={() => unpauseContract()}
              terminateContract={() => setShowTerminateDialog(true)}
            />
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="label">Contract URL</div>
              <div className="contract-url">{record.url || 'None'}</div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="label">Agreement initiated</div>
              <div>{formatContractLength(contractData.contractLength)}</div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-5">
              <div className="label">Initial fee</div>
              <div>{contractData.initialFee}</div>
            </div>
            <div className="col-md-5">
              <div className="label">Monthly fee</div>
              <div>{contractData.monthlyFee}</div>
            </div>
            <div className="col-md-2">
              <div className="label">Currency</div>
              <div>{contractData.currency}</div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-5">
              <div className="label">Commission model</div>
              <div className="value">{contractData.commissionModel}</div>
            </div>

            {commissionPriceModel === 'percentage' && (
              <div className="col-md-5">
                <div className="label">Commission percentage</div>
                <div className="value">
                  {contractData.commissionPercentage}%
                </div>
              </div>
            )}

            {commissionPriceModel === 'price' && (
              <div className="col-md-5">
                <div className="label">Commission price</div>
                <div className="value">{contractData.commissionPrice}</div>
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="label">Transaction decline period</div>
              <div className="value">
                {contractData.transactionDeclinePeriod}{' '}
                {contractData.transactionDeclinePeriodType}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="label">Invoice period</div>
              <div className="value">
                {contractData.invoicePeriod} {contractData.invoicePeriodType}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="label">Tracking period</div>
              <div className="value">
                {contractData.trackingPeriod} {contractData.trackingPeriodType}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="label">Tracking implementation</div>
              <div className="value">{contractData.trackingImplementation}</div>
            </div>
          </div>

          {isProposedUpdate && (
            <div className="form-actions">
              <hr />
              <div className="mt-2">
                <button className="btn btn-primary" onClick={signContract}>
                  Accept new terms
                </button>
              </div>
              <div className="mt-2">
                <button
                  className="btn btn-primary"
                  onClick={() => navigate(editContractPath(record.uuid))}
                >
                  Suggest other terms
                </button>
              </div>
              <div className="mt-2">
                <button className="btn btn-danger" onClick={declineContract}>
                  Decline new terms
                </button>
              </div>
            </div>
          )}
        </DetailsContainer>
      </Panel>

      <Sales record={record} />
      <Invoices record={record} />

      <DialogPause
        show={showPauseDialog}
        toggle={setShowPauseDialog}
        confirm={() => {
          pauseContract()
        }}
      />
      <DialogTerminate
        show={showTerminateDialog}
        toggle={setShowTerminateDialog}
        record={record}
        confirm={(values) => {
          terminateContract(values)
        }}
      />
    </>
  )
}
