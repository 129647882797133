import React from 'react'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import { Link } from '@reach/router'
import { headerHeight } from '../shared/elements'
import * as paths from '../../utils/paths'

export const sidenavWidth = 300

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: ${sidenavWidth}px;
  margin-top: ${headerHeight}px;
  background: var(--aside-background);

  ul {
    padding: 45px 5px 0;
    margin: 0;
    list-style: none;
  }

  li {
    a {
      position: relative;
      display: flex;
      padding: 16px 26px 16px 35px;
      border-radius: 6px;
      margin-bottom: 6px;
      overflow: hidden;

      svg {
        width: 22px;
        height: 22px;
        min-width: 22px;
        min-height: 22px;
        margin-right: 18px;
        color: white;
      }

      span {
        margin-top: 4px;
        line-height: 18px;
        font-size: 16px;
        color: white;
      }
    }

    a:hover {
      background: var(--aside-background-hover);
    }

    a.active {
      background: var(--aside-background-hover);

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 6px;
        background: #21cad2;
      }
    }
  }
`

const NavLink = (props) => {
  const isCurrent = window.location.pathname.startsWith(props.to)
  return <Link className={isCurrent ? 'active' : ''} {...props} />
}

export default function Sidenav(props) {
  return (
    <Aside>
      <div className="aside-top">
        <ul>
          <li>
            <NavLink to={paths.contractsPath()}>
              <SVG src="/images/icons/partnerships.svg" />
              <span>Partners</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={paths.invoicesPath()}>
              <SVG src="/images/icons/invoices.svg" />
              <span>Invoices</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </Aside>
  )
}
