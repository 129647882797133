import React from 'react'
import styled from 'styled-components'
import { getInitials } from '../../../utils/helpers'

const StyledInitials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  background: var(--input-background);
`

export default function Owner(props) {
  const { user } = props
  const name = user.name || ''
  const initials = getInitials(name)

  return <StyledInitials title={name}>{initials}</StyledInitials>
}
