import React from 'react'
import { Dialog } from '../../shared/elements'

export default function DialogPause(props) {
  const { show, toggle, confirm } = props

  return (
    <Dialog show={show} toggle={toggle}>
      <h3>Pause tracking</h3>
      <p>Do you wish to pause tracking?</p>
      <div className="dialog-actions">
        <button className="btn btn-primary" onClick={() => toggle(false)}>
          No keep running
        </button>
        <button className="btn btn-danger" onClick={() => confirm()}>
          Yes pause
        </button>
      </div>
    </Dialog>
  )
}
