import React, { useState, useRef } from 'react'
import { Editor, EditorState, ContentState, RichUtils } from 'draft-js'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'

const Container = styled.div`
  margin-bottom: 20px;
  border-radius: 6px;
  background: var(--input-background);

  .DraftEditor-root {
    position: relative;
  }

  .public-DraftEditorPlaceholder-root {
    position: absolute;
    top: 12px;
    left: 16px;
    color: var(--text-secondary);
    pointer-events: none;
  }

  .DraftEditor-editorContainer {
    [contenteditable] {
      min-height: 45px;
      padding: 12px 16px;
      transition: min-height 0.3s ease-in-out;
    }
  }

  .activity-editor-control {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out, padding 0.3s ease-in-out;

    .separator {
      position: absolute;
      top: 0;
      left: 10px;
      right: 52px;
      height: 1px;
      background: var(--text-muted);
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      margin-right: 6px;
      border: none;
      border-radius: 2px;
      background: var(--text-muted);
      transition: background 0.3s ease-in-out;

      &:hover {
        background: var(--text-background-hover);
      }

      svg {
        width: 18px;
        height: 18px;
        min-width: 18px;
      }
    }

    button.editor-submit {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0;
      bottom: 0;
      width: 42px;
      height: 40px;
      margin: 0;
      border-radius: 0;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      background: var(--text-primary);

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  &.focus {
    .DraftEditor-editorContainer {
      [contenteditable] {
        min-height: 130px;
      }
    }

    .activity-editor-control {
      height: 40px;
      padding: 9px 10px;
    }
  }

  &:not(.focus) {
  }
`

let focusTimeout

export default function NoteEditor(props) {
  const { onSubmit } = props

  const [noteState, setNoteState] = useState(() => EditorState.createEmpty())
  const [noteFocus, setNoteFocus] = useState(false)

  const onFocus = () => {
    setNoteFocus(true)

    if (focusTimeout) {
      clearTimeout(focusTimeout)
      focusTimeout = null
    }
  }

  const onBlur = () => {
    if (focusTimeout) {
      clearTimeout(focusTimeout)
    }

    focusTimeout = setTimeout(() => {
      setNoteFocus(false)
    }, 300)
  }

  const editorRef = useRef()

  const toggleBold = (event) => {
    event.preventDefault()
    setNoteState(RichUtils.toggleInlineStyle(noteState, 'BOLD'))
  }

  const toggleItalic = (event) => {
    event.preventDefault()
    setNoteState(RichUtils.toggleInlineStyle(noteState, 'ITALIC'))
  }

  const toggleUnderline = (event) => {
    event.preventDefault()
    setNoteState(RichUtils.toggleInlineStyle(noteState, 'UNDERLINE'))
  }

  const noteSubmit = (event) => {
    event.preventDefault()

    if (!editorRef.current) {
      return
    }

    if (!editorRef.current.editor.innerText.trim()) {
      return
    }

    onSubmit(getMessage())
    clearNote()
  }

  const getMessage = () => {
    const editor = editorRef.current.editor
    const node = editor.cloneNode(true)

    node.querySelectorAll('*').forEach((child) => {
      child.removeAttribute('class')
      for (let key in child.dataset) {
        delete child.dataset[key]
      }
    })

    node.firstElementChild.classList.add('activity-note')

    return node.innerHTML
  }

  const clearNote = () => {
    setNoteState(
      EditorState.push(
        noteState,
        ContentState.createFromText(''),
        'remove-range'
      )
    )
  }

  return (
    <Container className={noteFocus ? 'focus' : ''}>
      <Editor
        editorState={noteState}
        onChange={setNoteState}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={editorRef}
        placeholder="Add note"
      />
      <div className="activity-editor-control">
        <div className="separator" />
        <button onMouseDown={toggleBold}>
          <SVG src="/images/icons/editor-bold.svg" />
        </button>
        <button onMouseDown={toggleItalic}>
          <SVG src="/images/icons/editor-italic.svg" />
        </button>
        <button onMouseDown={toggleUnderline}>
          <SVG src="/images/icons/editor-underline.svg" />
        </button>
        <button className="editor-submit" onMouseDown={noteSubmit}>
          <SVG src="/images/icons/editor-submit.svg" />
        </button>
      </div>
    </Container>
  )
}
