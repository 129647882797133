import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Header from './Header'
import Sidenav, { sidenavWidth } from './Sidenav'
import { headerHeight } from '../shared/elements'
import Login from '../auth'
import { getCurrentUser } from '../../services/api'
import { checkLogin, setCurrentUser } from '../../services/auth'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../../../static/stylesheets/bundle.scss'

const Main = styled.main`
  position: relative;
  padding: 50px;
  margin-top: ${headerHeight}px;
  margin-left: ${sidenavWidth}px;
`

export default function Layout(props) {
  const [isAuthenticated, setAuthenticated] = useState(null)
  const [publisher, setPublisher] = useState(null)

  useEffect(() => {
    const authenticated = checkLogin()
    setAuthenticated(authenticated)

    if (authenticated) {
      getCurrentUser().then((response) => {
        if (response?.data?.user) {
          const user = response.data.user
          user.publisher = response?.data?.publisher
          setCurrentUser(user)
          setPublisher(user.publisher)
        } else {
          setCurrentUser(null)
        }
      })
    }
  }, [isAuthenticated])

  if (isAuthenticated === null) {
    return null
  }

  if (!isAuthenticated) {
    return <Login setAuthenticated={setAuthenticated} />
  }

  return (
    <div>
      <Header />
      <Sidenav publisher={publisher} />
      <Main>{props.children}</Main>
    </div>
  )
}
