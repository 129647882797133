import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { Link } from '@reach/router'
import SVG from 'react-inlinesvg'
import Form from './Form'
import Header from './shared/Header'
import Activities from './shared/Activities'
import ContractStatus from './shared/ContractStatus'
import { Panel, Toast, useToast } from '../shared/elements'
import * as API from '../../services/api'
import { contractsPath, contractPath } from '../../utils/paths'

export default function Edit(props) {
  const { uuid } = props

  const [record, setRecord] = useState(null)
  const [error, setError] = useToast()

  useEffect(() => {
    if (record === null) {
      API.getContract(uuid).then((response) => {
        if (response?.data?.contract) {
          setRecord(response.data.contract)
        }
      })
    }
  }, [uuid, record])

  const onSubmit = (submit) => async (values) => {
    const result = await API.updateContract(record.uuid, values, { submit })
    if (result?.data?.success) {
      navigate(contractsPath())
    } else {
      console.error(result)
      setError(result?.message)
    }
  }

  const onNoteSubmit = async (message) => {
    const response = await API.createNoteActivity({
      activity: {
        contractUuid: uuid,
        message,
      },
    })

    if (response?.data?.activity) {
      const newRecord = {
        ...record,
        activities: [response.data.activity, ...record.activities],
      }
      setRecord(newRecord)
    }
  }

  if (record === null) {
    return null
  }

  return (
    <>
      <Header>
        <Link to={contractPath(record.uuid)} className="back-link">
          <SVG src="/images/icons/arrow-left.svg" />
        </Link>
        <h4>{record.code}</h4>
        <ContractStatus record={record} />
      </Header>
      <div className="row mt-20">
        <div className="col-lg-8 mb-20">
          <Panel>
            <h4>Partnership agreement</h4>
            <Form isNew={false} initialValues={record} onSubmit={onSubmit} />
          </Panel>
        </div>
        <div className="col-lg-4 mb-20">
          <Panel className="full-height">
            <Activities
              activities={record.activities}
              onSubmit={onNoteSubmit}
            />
          </Panel>
        </div>
      </div>
      <Toast message={error} />
    </>
  )
}
