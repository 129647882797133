import React, { useState } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { formatMoney } from '../../../utils/helpers'
import * as API from '../../../services/api'

const StyledTable = styled.table.attrs((props) => ({
  className: 'table table-borderless',
}))`
  margin-top: 30px;

  thead {
    th,
    td {
      height: 32px;
      color: var(--text-secondary);
      font-size: 12px;
      font-weight: normal;
    }

    td {
      text-align: center;
    }
  }

  tbody {
    color: var(--text-primary);
    font-size: 12px;

    tr {
      height: 69px;
    }

    th,
    td {
      vertical-align: middle;
    }

    th {
      font-size: 14px;
      white-space: nowrap;
    }

    td {
      position: relative;
      text-align: center;
      font-weight: 500;
    }

    .with-separator {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 25%;
        right: 0;
        bottom: 25%;
        width: 1px;
        background: var(--text-muted);
      }
    }

    .minor {
      width: 0;
    }

    tr {
      > *:not(.spacer) {
        border-bottom: 1px solid var(--text-muted);
      }
    }

    tr {
      transition: box-shadow 0.5s ease-in-out;

      &:hover {
        box-shadow: 0px 1px 6px 0 rgba(156, 178, 211, 0.24);
        z-index: 1;
      }
    }

    .action-btn {
      margin-left: 8px;
      border: 1px solid currentColor;
      border-radius: 3px;
      color: #979cbc;
      background: white;
      font-size: 10px;
      font-weight: 500;
      white-space: nowrap;
      outline: none;
      transition: border-color 0.3s, color 0.3s;

      &:hover,
      &:focus {
        color: #293954;
      }

      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      svg {
        display: none;
      }

      &.active {
        color: #78d4c0;
        pointer-events: none;

        svg {
          display: inline-block;
          margin-right: 4px;
        }
      }

      &:not(.active) {
        margin-left: 20px;
      }
    }
  }

  .spacer {
    width: 30px;
    min-width: 30px;
    padding: 0;
  }
`

export default function InvoicesTable(props) {
  const { records, updateRecord, setMessage } = props

  const [submitting, setSubmitting] = useState(false)

  if (records === null) {
    return (
      <StyledTable>
        <thead>
          <tr>
            <td>Loading...</td>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <td className="text-center text-primary py-5">
              <div className="spinner-border" role="status" />
            </td>
          </tr>
        </tfoot>
      </StyledTable>
    )
  }

  const actionClassName = (record, key) => {
    if (record[key]) {
      return 'active'
    } else {
      return ''
    }
  }

  const send = (record) => async () => {
    if (submitting) return
    setSubmitting(true)

    const response = await API.updateInvoice(record.uuid, {
      invoice: {
        isSent: true,
      },
    })
    if (response) {
      if (response.code === 200) {
        updateRecord(response.data.invoice)
      } else {
        setMessage(response.message)
      }
    }

    setSubmitting(false)
  }

  const paid = (record) => async () => {
    if (submitting) return
    setSubmitting(true)

    const response = await API.updateInvoice(record.uuid, {
      invoice: {
        isPaid: true,
      },
    })
    if (response) {
      if (response.code === 200) {
        updateRecord(response.data.invoice)
      } else {
        setMessage(response.message)
      }
    }

    setSubmitting(false)
  }

  return (
    <StyledTable>
      <thead>
        <tr>
          <td className="spacer" />
          <td className="text-nowrap text-left">Advertiser ID</td>
          <td className="text-left">Advertiser name</td>
          <td className="text-right">Period</td>
          <td className="text-right">Amount</td>
          <td className="text-right">Mark as</td>
          <td className="spacer" />
        </tr>
      </thead>

      <tbody>
        {records.map((record) => (
          <tr key={record.uuid}>
            <td className="spacer" />
            <td className="minor text-left">
              #{record.advertiser?.tuneAdvertiserId}
            </td>
            <th className="with-separator text-left">
              {record.advertiser?.name}
            </th>
            <td className="with-separator text-right">{record.period}</td>
            <td className="with-separator text-right">
              {formatMoney(
                parseFloat(record.data?.totalPayout || 0),
                record.currency
              )}
            </td>
            <td className="minor text-nowrap text-right">
              <button
                className={`action-btn ${actionClassName(record, 'isSent')}`}
                onClick={send(record)}
              >
                <SVG src="/images/icons/invoice-tick.svg" />
                Invoice sent
              </button>
              <button
                className={`action-btn ${actionClassName(record, 'isPaid')}`}
                onClick={paid(record)}
                disabled={!record.isSent}
              >
                <SVG src="/images/icons/invoice-tick.svg" />
                Paid
              </button>
            </td>
            <td className="spacer" />
          </tr>
        ))}
      </tbody>

      {records.length === 0 && (
        <tfoot>
          <tr>
            <td colSpan="42" className="text-center text-primary">
              <div className="pt-4 text-secondary">Nothing found</div>
            </td>
          </tr>
        </tfoot>
      )}
    </StyledTable>
  )
}
