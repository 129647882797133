import React, { forwardRef } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import DatePicker from 'react-datepicker'

const Container = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 22px;
  margin-top: -10px;
  border-radius: 6px;
  background: var(--input-background);
  white-space: nowrap;

  svg {
    width: 22px;
    height: 22px;
    margin-right: 6px;
  }

  button.datepicker-input {
    border: none;
    color: var(--text-primary);
    background: transparent;
    font-size: 12px;
  }

  .react-datepicker {
    border: none;
    border-radius: 6px;
    box-shadow: 1px 3px 6px 0 rgba(156, 178, 211, 0.5);
    font-family: inherit;

    .react-datepicker__triangle {
      border-bottom-color: var(--body-background);

      &:before {
        border-bottom-color: var(--body-background);
      }
    }

    .react-datepicker__header {
      border: none;
      background: var(--body-background);

      .react-datepicker__current-month {
        color: var(--text-primary);
      }
    }

    .react-datepicker__header__dropdown {
      margin: 10px 0;

      .react-datepicker__month-read-view--down-arrow,
      .react-datepicker__year-read-view--down-arrow {
        top: 7px;
        border-width: 5px;
      }

      .react-datepicker__month-read-view--down-arrow {
        margin-left: 22px;
      }

      .react-datepicker__year-read-view--down-arrow {
        margin-left: 24px;
      }
    }

    .react-datepicker__day--selected {
      background-color: var(--text-primary);

      &:hover {
        background-color: var(--text-primary-hover);
      }
    }
  }
`

export default function SalesFilter(props) {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    firstDate,
    lastDate,
  } = props

  const onStartChange = (newDate) => {
    if (newDate > endDate) {
      const nextDate = new Date()
      nextDate.setDate(newDate.getDate() + 1)
      setStartDate(newDate)
      setEndDate(nextDate)
    } else {
      setStartDate(newDate)
    }
  }

  const onEndChange = (newDate) => {
    if (newDate < startDate) {
      const nextDate = new Date()
      nextDate.setDate(newDate.getDate() + 1)
      setStartDate(newDate)
      setEndDate(nextDate)
    } else {
      setEndDate(newDate)
    }
  }

  return (
    <Container>
      <SVG src="/images/icons/calendar.svg" />
      <DatePicker
        selected={startDate}
        onChange={onStartChange}
        startDate={startDate}
        endDate={endDate}
        minDate={firstDate}
        maxDate={lastDate}
        customInput={<DatepickerInput />}
        selectsStart
      />
      <span> - </span>
      <DatePicker
        selected={endDate}
        onChange={onEndChange}
        startDate={startDate}
        endDate={endDate}
        minDate={firstDate}
        maxDate={lastDate}
        customInput={<DatepickerInput />}
        selectsEnd
      />
    </Container>
  )
}

const DatepickerInput = forwardRef((props, _ref) => {
  const { value, onClick } = props

  return (
    <button className="datepicker-input" onClick={onClick}>
      {value}
    </button>
  )
})
