export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

export const errorMessages = {
  required: 'Required',
  invalidEmail: 'Invalid e-mail',
}

export const validates = {
  required: { required: errorMessages.required },
  email: {
    pattern: {
      value: emailRegex,
      message: errorMessages.invalidEmail,
    },
  },
}

export const currencies = [
  { label: 'USD', value: 'USD' },
  { label: 'EUR', value: 'EUR' },
  { label: 'DKK', value: 'DKK' },
  { label: 'SEK', value: 'SEK' },
  { label: 'NOK', value: 'NOK' },
  { label: 'GBP', value: 'GBP' },
]

export const commissionModels = [
  { label: 'CPA', value: 'CPA' },
  { label: 'CPC', value: 'CPC' },
  { label: 'CPM', value: 'CPM' },
  { label: 'CPS', value: 'CPS' },
]

export const periodTypes = [
  { label: 'days', value: 'days' },
  { label: 'weeks', value: 'weeks' },
  { label: 'months', value: 'months' },
]

export const billingTypes = [
  {
    label: 'Billing via Heylink',
    value: 'heylink',
    hint:
      'Heylink will send both invoice and payment automaticly each month. Tracking reports in Heylink will decide the billable amount.',
  },
  {
    label: 'Self billing',
    value: 'self',
    hint:
      'The two parties will handle invoicing themselves and agree upon terms for invoicing.',
  },
]

export const trackingImplementations = [
  { label: 'Server-to-Server', value: 'Server-to-Server' },
  { label: 'Client-to-Server', value: 'Client-to-Server' },
]

export const filterStatuses = [
  { label: 'Pending', value: 'pending' },
  { label: 'Contract sent', value: 'sent' },
  { label: 'Contract signed', value: 'signed' },
  { label: 'Tracking installed', value: 'installed' },
  { label: 'Running', value: 'running' },
]

export const listProgressStatusLabels = {
  pending: 'Pending',
  sent: 'Live',
  signed: 'Live',
  installed: 'Live',
  running: 'Live',
  paused: 'Paused',
  ended: 'Ended',
  terminated: 'Terminated',
}

export const listProgressStatusIndex = {
  pending: 1,
  sent: 2,
  signed: 3,
  installed: 4,
  running: 5,
  paused: 0,
  ended: 0,
  terminated: 0,
}
export const listProgressStatusIndexMax = Math.max(
  ...Object.values(listProgressStatusIndex)
)

export const topStatusLabels = {
  pending: 'Pending',
  sent: 'Agreement sent',
  signed: 'Agreement signed',
  installed: 'Tracking installed',
  running: 'Running',
  paused: 'Paused',
  ended: 'Ended',
  terminated: 'Terminated',
}

export const topStatusWaitLabels = {
  sent: 'Waiting for signature',
  signed: 'Waiting for install',
  installed: 'Waiting for first sale',
}

export const cmsOptions = ['Wordpress', 'Shopify', 'Other']

export const pdfPath = `${process.env.BACKEND_HOST}/contract`
