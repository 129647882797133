import React from 'react'
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import { findChartPausedLineIndex } from '../../../utils/chart'

const chartHeight = 200

export default function SalesChart(props) {
  const { record, data } = props

  if (!data || data.length <= 1) {
    return <div style={{ height: chartHeight }} />
  }

  const pauseLineIndex = findChartPausedLineIndex(record, data)

  return (
    <ResponsiveContainer width="100%" height={chartHeight}>
      <LineChart data={data} margin={{ top: 30, bottom: 10 }}>
        <CartesianGrid horizontal={false} />
        <ReferenceLine y={0} />
        {pauseLineIndex !== -1 && (
          <ReferenceLine
            x={pauseLineIndex}
            stroke="red"
            strokeWidth={2}
            isFront={true}
          />
        )}
        <Line
          type="monotone"
          dataKey="value"
          stroke="#78d4c0"
          strokeWidth={6}
          dot={false}
          activeDot={false}
        />
        <Tooltip
          formatter={(value) => Number(value).toFixed(3).replace(/0$/, '')}
          labelFormatter={(index) => data[index]?.label || ''}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}
