import React from 'react'
import styled from 'styled-components'
import { Panel } from '../../shared/elements'
import { formatStatTotal } from '../../../utils/chart'

const Container = styled.div`
  h5 {
    margin-bottom: 30px;
  }

  thead {
    td {
      height: 32px;
      color: var(--text-secondary);
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0.6px;
      white-space: nowrap;
    }

    .main {
      width: 100%;
    }

    .numeric {
      text-align: right;
    }
  }

  tbody {
    td {
      color: var(--text-primary);
      font-size: 14px;
      white-space: nowrap;
    }

    .numeric {
      text-align: right;
    }
  }
`

const Table = styled.table.attrs((props) => ({
  className: 'table table-borderless',
}))`
  tbody > tr:nth-child(even) > td {
    background: var(--input-background);
  }
`

export default function ContractInvoices(props) {
  const { record } = props

  if (!record.invoices || record.invoices.length === 0) {
    return null
  }

  return (
    <Container className="active">
      <Panel className="mt-20">
        <h5>Latest invoices</h5>
        <Table>
          <thead>
            <tr>
              <td className="main">Period</td>
              <td className="numeric">Payout</td>
            </tr>
          </thead>
          <tbody>
            {record.invoices.map((invoice) => (
              <tr key={invoice.uuid}>
                <td>{invoice.period}</td>
                <td className="numeric">
                  {formatStatTotal(
                    invoice.data.totalPayout,
                    record.data.currency
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel>
    </Container>
  )
}
