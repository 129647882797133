import moment from 'moment'

export const getFirstStatsDate = (record) => {
  const createdAt = new Date(record.createdAt)
  const monthAgo = moment().subtract(1, 'month').toDate()
  if (monthAgo < createdAt) {
    return monthAgo
  } else {
    return createdAt
  }
}

export const formatStatTotal = (value, currency) =>
  `${Number(value).toFixed(2)} ${currency}`

export const formatChartDataFromStats = (stats, startDate, endDate) => {
  const result = []
  let day, nextDay
  let label, stat

  const findStat = (date) =>
    stats.data.find((entry) => entry.Stat.date === date)

  day = new Date(startDate)
  while (day <= endDate) {
    label = moment(day).format('D MMM')
    stat = findStat(moment(day).format('YYYY-MM-DD'))

    if (stat) {
      result.push({
        label,
        value: parseFloat(stat.Stat.revenue),
      })
    } else {
      result.push({
        label,
        value: 0,
      })
    }

    nextDay = day.setDate(day.getDate() + 1)
    day = new Date(nextDay)
  }

  return result
}

export const findChartPausedLineIndex = (record, data) => {
  if (!record.pausedAt) {
    return -1
  }

  const label = moment(record.pausedAt).format('D MMM')
  return data.findIndex((datum) => datum.label === label)
}
