import React, { useState, useEffect } from 'react'
import { Link } from '@reach/router'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import Filter from './list/Filter'
import Table from './list/Table'
import Pagination from './list/Pagination'
import { Panel, NoPanelGutter } from '../shared/elements'
import * as API from '../../services/api'
// import { getCurrentUser } from '../../services/auth'
import { newContractPath } from '../../utils/paths'

const perPage = 10

const Container = styled.div`
  .header {
    display: flex;
    flex-direct: row;
    align-items: center;

    .add,
    .edit-defaults {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      padding: 14px 20px 12px;
      border-radius: 4px;
      color: white;
      background: var(--text-primary);
      font-size: 16px;

      svg {
        margin-top: -2px;
        margin-left: 10px;
      }
    }

    .edit-defaults {
      margin-left: 8px;
    }
  }
`

export default function List(props) {
  const [records, setRecords] = useState(null)
  const [filterQuery, setFilterQuery] = useState('')
  const [filterStatus, setFilterStatus] = useState('')
  const [order, setOrder] = useState('last_activity_at')
  const [sort, setSort] = useState('desc')
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)

  const gotoPage = (newPage) => {
    if (page !== newPage) {
      setPage(newPage)
      setRecords(null)
    }
  }

  useEffect(() => {
    if (!records) {
      API.getContracts({
        search: filterQuery,
        status: filterStatus,
        order,
        sort,
        page,
        perPage,
      }).then((response) => {
        if (response?.data) {
          setRecords(response.data.contracts)
          setTotal(response.data.total)
        }
      })
    }
  }, [records, filterQuery, filterStatus, order, sort, page])

  // const publisher = getCurrentUser()?.publisher
  // const hasDefaultTerms = !!publisher?.isListedSource

  return (
    <Panel>
      <Container>
        <h4 className="mb-30">Partners</h4>
        <div className="header">
          <Link to={newContractPath()} className="add inline-flex-center">
            Add <SVG src="/images/icons/plus.svg" />
          </Link>
          {/*hasDefaultTerms && (
            <Link
              to={contractPath('default')}
              className="edit-defaults inline-flex-center"
            >
              Edit default terms
            </Link>
          )*/}
          <Filter
            {...{
              filterQuery,
              setFilterQuery,
              filterStatus,
              setFilterStatus,
              setRecords,
              setPage,
            }}
          />
        </div>
        <NoPanelGutter>
          <Table
            {...{
              records,
              setRecords,
              order,
              setOrder,
              sort,
              setSort,
              setPage,
            }}
          />
        </NoPanelGutter>
        <Pagination
          page={page}
          perPage={perPage}
          setPage={gotoPage}
          total={total}
        />
      </Container>
    </Panel>
  )
}
