import React from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { topStatusLabels, topStatusWaitLabels } from '../../../utils/constants'
import { getExtendedContractStatus } from '../../../utils/helpers'

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direct: row;
  align-items: center;
  font-size: 12px;

  .label {
    margin-right: 8px;
    color: var(--text-secondary);
  }

  .current-status,
  .wait-status {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    background: white;
    font-family: var(--head-font);
    font-size: 14px;

    svg {
      margin-right: 10px;
    }
  }

  .next-status-icon {
    margin: 0 14px;
    color: var(--text-secondary);
  }

  svg {
    width: 22px;
    height: 22px;
  }
`

export default function ContractStatus(props) {
  const { record } = props

  if (!record) {
    return null
  }

  const status = getExtendedContractStatus(record)

  const statusLabel = topStatusLabels[status]
  const waitLabel = topStatusWaitLabels[status]

  return (
    <Container>
      <div className="label">Current status:</div>
      <span className="current-status text-primary">
        <SVG src={`/images/icons/status-${status}.svg`} />
        {statusLabel}
      </span>
      {waitLabel && (
        <>
          <span className="next-status-icon">
            <SVG src="/images/icons/status-next.svg" />
          </span>
          <span className="wait-status text-primary">
            <SVG src="/images/icons/status-wait.svg" />
            {waitLabel}
          </span>
        </>
      )}
    </Container>
  )
}
