import React, { useState } from 'react'
import { Link } from '@reach/router'
import { navigate } from 'gatsby'
import SVG from 'react-inlinesvg'
import Form from './Form'
import Header from './shared/Header'
import Activities from './shared/Activities'
import { Panel, Toast, useToast } from '../shared/elements'
import { getCurrentUser } from '../../services/auth'
import * as API from '../../services/api'
import { contractsPath } from '../../utils/paths'

const initialActivity = () => ({
  id: 0,
  type: 'init',
  message: `New agreement initiated by <b>${getCurrentUser().name}</b>`,
})

export default function New(props) {
  const [notes, setNotes] = useState([])
  const [error, setError] = useToast()
  const [activities, setActivities] = useState([initialActivity()])

  const onSubmit = (submit) => async (values) => {
    API.createContract(values, { submit, notes })
      .then((result) => {
        if (result?.data?.success) {
          navigate(contractsPath())
        } else {
          console.error(result)
          setError(result.message)
        }
      })
      .catch((error) => {
        console.error(error)
        setError(error)
      })
  }

  const onNoteSubmit = (message) => {
    setNotes([...notes, message])
    const newActivity = {
      id: activities.length,
      type: 'note',
      message: `Note by <b>${getCurrentUser().name}</b><br />${message}`,
    }
    setActivities([newActivity, ...activities])
  }

  return (
    <div className="row">
      <div className="col-lg-8 mb-20">
        <Panel>
          <Header>
            <Link to={contractsPath()} className="back-link">
              <SVG src="/images/icons/arrow-left.svg" />
            </Link>
            <h4>New partnership agreement</h4>
          </Header>
          <Form isNew={true} onSubmit={onSubmit} />
        </Panel>
      </div>
      <div className="col-lg-4 mb-20">
        <Panel className="full-height">
          <Activities activities={activities} onSubmit={onNoteSubmit} />
        </Panel>
      </div>
      <Toast message={error} />
    </div>
  )
}
