import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import styled from 'styled-components'
import Filter from './list/Filter'
import Table from './list/Table'
import Pagination from './list/Pagination'
import { Panel, NoPanelGutter, Toast, useToast } from '../shared/elements'
import * as API from '../../services/api'

const Container = styled.div``

const initialMonth = moment().format('MMMM')
const initialYear = moment().format('YYYY')

const perPage = 20

export default function List(props) {
  const [records, setRecords] = useState(null)
  const [recordsTotal, setRecordsTotal] = useState(0)

  const updateRecord = (newRecord) => {
    const newRecords = cloneDeep(records)
    const index = newRecords.findIndex(
      (record) => record.uuid === newRecord.uuid
    )
    newRecords[index] = { ...newRecords[index], ...newRecord }
    setRecords(newRecords)
  }

  const [page, setPage] = useState(1)
  const [filterQuery, setFilterQuery] = useState('')
  const [filterMonth, setFilterMonth] = useState(initialMonth)
  const [filterYear, setFilterYear] = useState(initialYear)
  const [message, setMessage] = useToast()

  useEffect(() => {
    if (records === null) {
      API.getInvoices({
        search: filterQuery,
        month: filterMonth,
        year: filterYear,
        page,
        perPage,
      }).then((response) => {
        setRecords(response?.data?.invoices)
        setRecordsTotal(response?.data?.total)
      })
    }
  }, [records, filterQuery, filterMonth, filterYear, page])

  const gotoPage = (newPage) => {
    if (newPage !== page) {
      setPage(newPage)
      setRecords(null)
    }
  }

  const reloadRecords = () => {
    setPage(1)
    setRecords(null)
    setRecordsTotal(0)
  }

  return (
    <>
      <Panel>
        <Container>
          <h4 className="mb-30">Invoices</h4>
          <div className="header">
            <Filter
              {...{
                filterQuery,
                setFilterQuery,
                filterMonth,
                setFilterMonth,
                filterYear,
                setFilterYear,
                reloadRecords,
              }}
            />
          </div>
          <NoPanelGutter>
            <Table
              records={records}
              setMessage={setMessage}
              updateRecord={updateRecord}
            />
          </NoPanelGutter>
          <Pagination
            page={page}
            perPage={perPage}
            setPage={gotoPage}
            total={recordsTotal}
          />
        </Container>
      </Panel>
      <Toast message={message} />
    </>
  )
}
