import React from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { filterStatuses } from '../../../utils/constants'

const FilterQuery = styled.div`
  display: inline-block;
  position: relative;
  flex: 1;
  margin-left: 10px;

  button.submit,
  button.cancel {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    height: 46px;
    background: transparent;
    border: none;
    outline: none;
    appearance: none;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  button.submit {
    justify-content: flex-end;
    left: 0;
    width: 46px;
  }

  button.cancel {
    justify-content: flex-start;
    right: 0;
    width: 40px;
    opacity: 0.4;
  }

  input {
    width: 100%;
    height: 46px;
    padding: 0 52px;
    background: var(--input-background);
    border: none;
    border-radius: 4px;

    &::placeholder {
      color: var(--text-secondary);
    }
  }
`

const FilterStatus = styled.div`
  margin-left: 10px;

  select {
    width: 180px;
    height: 46px;
    padding: 10px 14px;
    border: none;
    border-radius: 4px;
    background: var(--input-background);
  }
`

export default function ContractFilter(props) {
  const {
    filterQuery,
    setFilterQuery,
    filterStatus,
    setFilterStatus,
    setRecords,
    setPage,
  } = props

  return (
    <>
      <FilterQuery>
        <button
          type="button"
          className="submit"
          onClick={() => {
            setRecords(null)
            setPage(1)
          }}
        >
          <SVG src="/images/icons/search.svg" />
        </button>
        <input
          value={filterQuery}
          onChange={(event) => {
            setFilterQuery(event.target.value)
          }}
          onKeyDown={(event) => {
            switch (event.key) {
              case 'Enter':
                setRecords(null)
                setPage(1)
                break
              case 'Escape':
                setFilterQuery('')
                break
              default:
            }
          }}
          placeholder="Search"
        />
        {filterQuery && (
          <button
            type="button"
            className="cancel"
            onClick={() => {
              setFilterQuery('')
              setRecords(null)
              setPage(1)
            }}
          >
            <SVG src="/images/icons/times.svg" />
          </button>
        )}
      </FilterQuery>
      <FilterStatus>
        <select
          className="text-primary"
          value={filterStatus}
          onChange={(event) => {
            setFilterStatus(event.target.value)
            if (filterStatus !== event.target.value) {
              setRecords(null)
              setPage(1)
            }
          }}
          onBlur={(event) => {
            setFilterStatus(event.target.value)
            if (filterStatus !== event.target.value) {
              setRecords(null)
              setPage(1)
            }
          }}
        >
          <option value="">Status</option>
          {filterStatuses.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </FilterStatus>
    </>
  )
}
