import React from 'react'
import { CollapsibleFieldset } from './shared'
import { TextField } from '../../shared/fields'
import { validates } from '../../../utils/constants'

export default function FieldsetManager(props) {
  const { form, isDefault } = props

  return (
    <CollapsibleFieldset label={isDefault ? `Manager` : 'Manager*'}>
      <div className="row">
        <div className="col-md-6">
          <TextField
            form={form}
            validation={validates.required}
            name="data.managerName"
            label="Name"
          />
        </div>
        <div className="col-md-6">
          <TextField
            form={form}
            validation={{ ...validates.required, ...validates.email }}
            name="data.managerEmail"
            label="E-mail"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <TextField
            form={form}
            validation={validates.required}
            name="data.managerPosition"
            label="Position"
          />
        </div>
      </div>
    </CollapsibleFieldset>
  )
}
