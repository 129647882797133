import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { TextField } from '../shared/fields'
import { login } from '../../services/auth'

export default function LoginForm(props) {
  const { setAuthenticated } = props
  const [isSubmitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')

  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = async (values) => {
    setError('')
    setSubmitting(true)

    const loggedIn = await login(values)
    setSubmitting(false)

    if (loggedIn) {
      setAuthenticated(true)
    } else {
      setError('Incorred email or password')
    }
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <TextField
        form={form}
        validation={{ required: true }}
        type="email"
        name="email"
        label="Email"
      />
      <TextField
        form={form}
        validation={{ required: true }}
        type="password"
        name="password"
        label="Password"
      />

      {error && <div className="text-danger mb-3">{error}</div>}

      <div className="actions text-right mt-3">
        <button
          type="submit"
          disabled={isSubmitting}
          className="btn btn-primary full-width"
        >
          Login
        </button>
      </div>
    </form>
  )
}
