import React, { useState } from 'react'
import styled from 'styled-components'
import { Dialog } from '../../shared/elements'

const terminationReasons = {
  breach: 'breach',
  mutual: 'mutual',
}

const RadioContainer = styled.div`
  margin-bottom: 14px;

  .custom-control-input {
    position: absolute;
    width: 100%;
    height: 20px;
    opacity: 0;
    cursor: pointer;
  }

  .custom-control-indicator {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid var(--text-primary);
    border-radius: 10px;
    margin-right: 10px;
    vertical-align: top;
    pointer-events: none;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      left: 3px;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background: var(--text-primary);
      opacity: 0;
      transition: opacity 0.15s ease-in-out;
    }
  }

  .custom-control-input:checked + .custom-control-indicator {
    &:after {
      opacity: 1;
    }
  }
`

const TextareaContainer = styled.div`
  position: relative;
  margin-top: 52px;

  textarea {
    height: 140px;
    padding: 12px 20px;
    border: none;
    border-radius: 6px;
    background: var(--input-background);
    font-size: 14px;
    resize: none;

    &::placeholder {
      color: var(--text-secondary);
    }
  }

  .hint {
    position: absolute;
    top: -22px;
    right: 0;
    font-size: 12px;
    color: var(--text-secondary);
  }
`

export default function DialogTerminate(props) {
  const { show, toggle, confirm, record } = props

  const [terminationReason, setTerminationReason] = useState(
    terminationReasons.breach
  )
  const [terminationDescription, setTerminationDescription] = useState('')

  return (
    <Dialog show={show} toggle={toggle}>
      <h3>Terminate contract</h3>
      <p>
        Do you wish to terminate this agreement with {record.data.companyName}{' '}
        named <b>{record.code}</b>?
      </p>
      <p>
        Choose and describe the reason for termination of this contract. The
        termination will also be confirmed by {record.data.companyName}.
      </p>

      <RadioContainer>
        <label>
          <input
            type="radio"
            className="custom-control-input"
            name="terminationReason"
            value={terminationReasons.breach}
            onChange={(event) => setTerminationReason(event.target.value)}
            checked={terminationReason === terminationReasons.breach}
          />
          <div className="custom-control-indicator" />
          Breach of contract
        </label>
      </RadioContainer>
      <RadioContainer>
        <label>
          <input
            type="radio"
            className="custom-control-input"
            name="terminationReason"
            value={terminationReasons.mutual}
            onChange={(event) => setTerminationReason(event.target.value)}
            checked={terminationReason === terminationReasons.mutual}
          />
          <div className="custom-control-indicator" />
          Mutual agreement
        </label>
      </RadioContainer>

      <TextareaContainer>
        {terminationDescription.length < 50 && (
          <div className="hint">Min. 50 letters</div>
        )}
        <textarea
          className="form-control"
          name="terminationDescription"
          placeholder="Describe reason for termination*"
          onChange={(event) => setTerminationDescription(event.target.value)}
        />
      </TextareaContainer>

      <div className="dialog-actions">
        <button className="btn btn-primary" onClick={() => toggle(false)}>
          No keep running
        </button>
        <button
          className="btn btn-danger"
          onClick={() => {
            if (terminationDescription.length < 50) {
              return document
                .querySelector('[name=terminationDescription]')
                .focus()
            }

            confirm({
              terminationReason,
              terminationDescription,
            })
          }}
        >
          Yes terminate
        </button>
      </div>
    </Dialog>
  )
}
