import React from 'react'
import moment from 'moment'
import SVG from 'react-inlinesvg'
import styled from 'styled-components'
import NoteEditor from './NoteEditor'

const Activity = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
  margin-bottom: -1px;
  border-top: 1px solid var(--text-muted);
  border-bottom: 1px solid var(--text-muted);

  .activity-icon {
    margin-right: 20px;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  .activity-body {
    flex: 1;
    font-size: 14px;
    word-break: break-word;
  }

  .activity-time {
    margin-bottom: 2px;
    color: var(--text-secondary);
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.6px;
  }

  .activity-message {
    font-family: var(--body-secondary-font);
    font-size: 13px;

    .activity-note {
      margin-top: 10px;
      margin-left: 12px;
      quotes: '“' '”';

      > *:first-child {
        > div {
          &:before {
            content: open-quote;
          }
        }
      }

      > *:last-child {
        > div {
          &:after {
            content: close-quote;
          }
        }
      }
    }
  }
`

const formatDatetime = (datetime) =>
  moment(datetime).format('MMMM DD, YYYY, hh:mm A z')

export default function Activities(props) {
  const { activities, onSubmit } = props

  return (
    <>
      <h4>Timeline</h4>
      <NoteEditor onSubmit={onSubmit} />
      {activities &&
        activities.map((activity) => (
          <Activity key={activity.id}>
            <span className="activity-icon">
              <SVG src={`/images/icons/activity-${activity.type}.svg`} />
            </span>
            <div className="activity-body">
              <div className="activity-time">
                {formatDatetime(activity.createdAt)}
              </div>
              <div
                className="activity-message"
                dangerouslySetInnerHTML={{ __html: activity.message }}
              />
            </div>
          </Activity>
        ))}
    </>
  )
}
